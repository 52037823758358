@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body,
#root {
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

button {
  background-color: transparent;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  height: 12px;
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: #76829259;
}

/* making slick slider underneath slides */
.slick-dots-bottom {
  bottom: -42px !important;
}

.ant-pro-setting-drawer-handle {
  display: none !important;
}

.inner-shadow {
  box-shadow: inset 4px 4px 4px rgba(121, 63, 249, 0.6);
}

.purple-shadow {
  box-shadow: 0px 8px 10px rgba(189, 0, 255, 0.25);
}

.slick-track {
  height: auto !important;
}

.button-background {
  background: linear-gradient(135.31deg, #793ff9 -0.44%, #22193c 102.83%) !important;
  border-radius: 8px !important;
}