#firebaseui-auth-container {
    width: 100%;
}

.firebaseui-container {
    max-width: 100%;
    background-color: transparent;
    box-shadow: none;    
}


/* remove padding from the content, mainly input */
.firebaseui-card-content {
    padding: 0;
}

/* remove padding from action buttons */
.firebaseui-card-actions {
    padding: 0;
}

.firebaseui-title {
    display: none;
}

/* input wrapper */
.firebaseui-textfield {
    box-sizing: border-box;
    position: static;
    padding: 0;
}

/* remove material ui jumpy jumpy label */
.firebaseui-label {
    display: none;
}

/* customization to input */
.mdl-textfield__input {
    border: none;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
}

.firebaseui-error-wrapper {
    margin-top: 24px;
}

.firebaseui-input-floating-button {
    top: 8px;
    right: 5px;
}

.firebaseui-id-country-selector {
    margin: 0;
    background-color: #fff;
    border-radius: 3px 0 0 3px;
}

#ui-sign-in-phone-number-input {
    border-radius: 0 3px 3px 0;
}

.firebaseui-text {
    color: #fff;
    display: flex;
    gap: 10px;
}